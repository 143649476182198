<script lang="ts">
	import InfoCard from "@components/InfoCard.svelte";
	import type { TechnologyCardProps } from "./TechnologyCardProps.js";

	const { technologies }: { technologies: TechnologyCardProps[] } = $props();
</script>

<section class="bg-white full-width max-lg:px-0 grid-container border-fstlight-100 border-t-4">
	<div class="grid grid-cols-2 lg:grid-cols-3 gap-1 max-lg:full-width lg:px-1 bg-fstlight-100">
		{#each technologies as { title, iconName, items }, index (index)}
			<InfoCard {title} {iconName} indexNumber={index + 1} {items} />
		{/each}
	</div>
</section>
